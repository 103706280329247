import * as React from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser";
import Icon from "../components/UI/Icon";
import { Link, useStaticQuery, graphql } from "gatsby"

const Footer = ({ siteTitle }) => {
   const data = useStaticQuery(
     graphql`
       query FooterQuery {
         contentJson(fileID: {eq: "infos"}) {
           fileID
           opentime {
             title
             days {
               label
               text
             }
           }
           contact {
             title
             items {
               url
               type
               text
               display
             }
           }
           social {
             title
             items {
               type
               url
               label
             }
           }
         }
       }
     `
   )
   const content = data.contentJson;
  /*useFirestoreConnect([
    { collection: 'info', doc: 'infos-restaurant' }
  ])
  const content = useSelector(({ firestore: { data } }) => data.info && data.info['infos-restaurant'])
  if (typeof content === 'undefined') {
    return null
  }*/
  return (
    <footer className="bg-gray-100">
      <div className="container mx-auto divide-y">
        <div className="py-6 md:flex md:flex-wrap	md:justify-between">

          {content.opentime.days && (
            <div className="py-6 w-auto md:w-1/2 lg:w-auto">
              <p className="text-lg font-bold mb-4 sm:text-center md:text-left">{content.opentime.title ? content.opentime.title : "Horaires"}</p>
              <div className="sm:flex sm:justify-center md:justify-start">
                <div>
                  {content.opentime.days.map((item, i) => (
                    <div key={i} className="sm:flex pb-2 last:pb-0">
                      <div className="w-24 mr-6 italic">{item.label}</div>
                      <div>{item.text}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          <div className="py-6 w-auto md:w-1/2 lg:w-auto">
            <p className="text-lg font-bold mb-4 sm:text-center md:text-left">{content.contact.title ? content.contact.title : "Contact"}</p>
            <div className="sm:flex sm:justify-center md:justify-start">
              <div>
                {content.contact.items.map((item, i) => (
                  item.display && (
                    <div key={i} className="pb-4 last:pb-0">
                      <a href={item.url} className={"flex no-underline hover:text-primary-dark" + (item.type !== "address" ? " items-center" : "")}>
                      {item.type === "address" && (
                        <Icon icon="location" className="mt-0.5 text-primary" />
                      )}
                      {item.type === "mail" && (
                        <Icon icon="mail" className="text-primary" />
                      )}
                      {item.type === "phone" && (
                        <Icon icon="phone" className="text-primary" />
                      )}
                      <span className="ml-2">{parse(item.text)}</span>
                      </a>
                    </div>
                  )
                ))}
              </div>
            </div>
          </div>

          <div className="py-6 w-auto md:w-1/2 lg:w-auto">
          </div>

          <div className="py-6 w-auto md:w-1/2 lg:w-auto">
            <p className="text-lg font-bold mb-4 sm:text-center md:text-left">{content.social.title ? content.social.title : "Réseaux sociaux"}</p>
            <div className="sm:flex sm:justify-center md:justify-start">
              <div>
                {content.social.items.map((item, i) => (
                  <div key={i} className="pb-4 last:pb-0">
                    <a href={item.url} className="flex items-center no-underline hover:text-primary-dark">
                      {item.type === "facebook" && (
                        <Icon icon="facebook" className="text-primary" />
                      )}
                      {item.type === "youtube" && (
                        <Icon icon="youtube" className="text-primary" />
                      )}
                      {item.type === "linkedin" && (
                        <Icon icon="linkedin" className="text-primary" />
                      )}
                      {item.type === "instagram" && (
                        <Icon icon="instagram" className="text-primary" />
                      )}
                      {item.type === "twitter" && (
                        <Icon icon="twitter" className="text-primary" />
                      )}
                      {item.type === "tiktok" && (
                        <Icon icon="tiktok" className="text-primary" />
                      )}
                      <span className="ml-2">{item.label}</span>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>

        </div>
        <div className="pb-4 pt-2 md:flex md:justify-between text-xs text-gray-500">
          <div className="text-center pt-2"><span className="text-primary">©</span> {new Date().getFullYear()} {siteTitle}</div>
          <div className="text-center pt-2">Réalisé par{` `}<a href="https://www.la-fabrik.nc" target="_blank" rel="noreferrer">La Fabrik</a></div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

export default Footer
