import * as React from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"
// import { useLocation } from '@reach/router';
import CustomImage from "../components/UI/CustomImage"
import Icon from "../components/UI/Icon";

const Header = ({ siteTitle }) => {
  // const location = useLocation()
  const data = useStaticQuery(
    graphql`
      query HeaderQuery {
        contentJson(fileID: {eq: "infos"}) {
          fileID
          contact {
            items {
              url
              type
              text
              display
            }
          }
          logo {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 150)
            }
          }
        }
      }
    `
  )
  const content = data.contentJson
  return (
    <header>
      <div className="p-4 flex justify-between items-center">
        <Link to="/">
          <span className="hidden">{siteTitle}</span>
          <CustomImage img={content.logo} alt={siteTitle} width="150px" />
        </Link>
      </div>
    </header>
  )
}  

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
