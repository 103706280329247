import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const CustomImage = ({ img, alt, ...props}) => {
  alt = alt || ""
  img = img || false
  const isSVG = img.childImageSharp == null ? true : false
  const image = img ? isSVG ? img.publicURL : getImage(img) : null
  if (image) {
    if (isSVG) {
      return (
        <img src={image} alt={alt} {...props} />
      )
    }
    return (
      <GatsbyImage image={image} alt={alt} {...props} />
    );
  }
  return null;
};

export default CustomImage